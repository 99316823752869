.add-market-form form {
    width: 75%;
    margin: auto;
    background: rgb(240, 240, 240);
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    padding: 20px 25px;
}

.add-market-form legend {
    margin-bottom: 15px;
}

.form-container {
    display: flex;
    column-gap: 50px;
    justify-content: center;
}

.form-container div {
    flex-basis: 33%;
    display: flex;
    flex-direction: column;
}

#same-address-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#same-address-container label {
    width: max-content;
}

.add-market-form select, .add-market-form input[type="text"] {
    padding: 10px 15px;
    margin: 0;
    margin-top: 10px;
}

.add-market-form button {
    width: fit-content;
    align-self: center;
}

.hidden {
    visibility: hidden;
}