:root {
  --main: #90AFC5;
  --dark-gray: #3E363F;
  --light-gray: #bdb4be;
  --light: #C4DFE6;
}

html {
  font-family: 'Fira Sans', sans-serif;
  color: var(--dark-gray)
}

.dashboard-menu-container {
  margin-top: 3%;
}

.main {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  padding: 2% 5%;
  background: rgb(250, 250, 250);
}

.products {
  flex-basis: 70%;
}

.add-product-form {
  flex-basis: 20%;
} 

/* form */

.add-product-form label, .add-product-form input {
  display: block;
}

.add-product-form input {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.add-product-form button {
  background: var(--dark-gray);
  border: 0;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.error-input {
  border-color: red;
}

.error {
  color: red;
}

/* select market stylings */
.add-new-market {
  color: var(--main);
}

.add-market-icon-container {
  border: 1px solid var(--main);
  width: fit-content;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.add-market-icon-container:hover {
  background: var(--main);
  color: white;
}

.hidden {
  visibility: hidden;
}